<template>
    <v-card outlined height="100%" color="primary" class="mt-2">
        <loader
            :isLoading="isLoading"
            :error="error"
            errorMessage="users.index.error"
        >
            <v-card-text>
                <v-card-title class="pa-0">
                    {{ $t("users.index.title") }}
                </v-card-title>
                <v-card-text class="pa-0">
                    <p v-if="users.length == 0">
                        {{ $t("users.index.empty") }}
                    </p>

                    <template v-else>
                        <v-data-table
                            :headers="headers"
                            :items="users"
                            :items-per-page="10"
                            :page.sync="page"
                            hide-default-header
                            hide-default-footer
                            @page-count="pageCount = $event"
                            mobile-breakpoint="0"
                        >
                            <template v-slot:[`item.actions`]="{ item }">
                                <v-icon class="mr-2" @click="goToMember(item)">
                                    mdi-badge-account-outline
                                </v-icon>
                                <v-icon @click="onDelete(item)">
                                    mdi-account-off
                                </v-icon>
                            </template>
                        </v-data-table>
                        <div class="text-center pt-2">
                            <v-pagination
                                v-model="page"
                                :length="pageCount"
                            ></v-pagination>
                        </div>
                    </template>
                </v-card-text>
            </v-card-text>

            <v-dialog v-model="deleteDialog" max-width="500">
                <v-card>
                    <v-card-title>
                        {{ $t("users.deleteDialog.title") }}
                    </v-card-title>

                    <v-card-text v-if="isLoadingDelete" class="text-center">
                        <v-progress-circular
                            :size="50"
                            color="primary"
                            indeterminate
                        ></v-progress-circular>
                        <br />
                        {{ $t("users.deleteDialog.isLoading") }}
                    </v-card-text>
                    <v-card-text v-else>
                        {{ $t("users.deleteDialog.text") }}

                        <v-alert v-if="errorDelete" class="pa-0 error--text">
                            {{ $t("users.deleteDialog.error") }}
                        </v-alert>
                    </v-card-text>

                    <v-card-actions v-if="!isLoadingDelete">
                        <v-spacer></v-spacer>
                        <v-btn color="primary" text @click="deleteUser()">
                            {{ $t("button.yes") }}
                        </v-btn>
                        <v-btn text @click="deleteDialog = false">
                            {{ $t("button.no") }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </loader>
    </v-card>
</template>

<script>
import Loader from "@/components/utils/Loader";

export default {
    components: {
        Loader,
    },

    data() {
        return {
            headers: [
                {
                    text: "",
                    value: "email",
                },
                { text: "", value: "actions", sortable: false, align: "end" },
            ],
            page: 1,
            pageCount: 0,

            isLoading: false,
            error: false,
            users: [],

            deleteDialog: false,
            selectedUser: null,
            isLoadingDelete: false,
            errorDelete: false,
        };
    },

    mounted() {
        this.getUsers();
    },

    methods: {
        getUsers() {
            this.isLoading = true;
            this.error = false;

            this.$store
                .dispatch("ohme/getUsers")
                .then((data) => {
                    this.users = data.data.users;
                    this.isLoading = false;
                })
                .catch((err) => {
                    if (
                        err !== undefined &&
                        err.response !== undefined &&
                        err.response.status == 403
                    ) {
                        this.$router.push({ name: "403" });
                    } else {
                        this.isLoading = false;
                        this.error = true;
                    }
                });
        },

        goToMember(user) {
            this.$router.push({
                name: "Member",
                params: {
                    memberId: user.ohme_user_id,
                },
            });
        },

        onDelete(user) {
            this.deleteDialog = true;
            this.selectedUser = Object.assign({}, user);
        },

        deleteUser() {
            this.isLoadingDelete = true;
            this.errorDelete = null;

            this.$store
                .dispatch("ohme/deleteUser", this.selectedUser.id)
                .then(() => {
                    this.deleteDialog = false;
                    this.isLoadingDelete = false;
                    this.getUsers();
                })
                .catch((err) => {
                    if (
                        err !== undefined &&
                        err.response !== undefined &&
                        err.response.status == 403
                    ) {
                        this.errorDelete = "403";
                    } else {
                        this.errorDelete = "error";
                    }
                    this.isLoadingDelete = false;
                });
        },
    },
};
</script>
