<template>
    <v-card outlined height="100%" color="primary" class="mt-2">
        <v-card-text>
            <v-card-title class="pa-0">
                {{ $t("memberships.title") }}
            </v-card-title>

            <v-card-text class="pa-0">
                <p v-if="associationsMembership.length == 0">
                    {{ $t("memberships.empty") }}
                </p>
                <v-list>
                    <v-list-item
                        v-for="(membership, index) in associationsMembership"
                        :key="index"
                        :to="{
                            name: 'AssociationCampaignForm',
                            params: {
                                campaignId: membership.campaign_id,
                                associationId: membership.association_id,
                            },
                        }"
                    >
                        {{
                            $t("homePage.membership", {
                                name: membership.association_name,
                                year: membership.campaign_year,
                            })
                        }}
                    </v-list-item>
                </v-list>
            </v-card-text>
        </v-card-text>
    </v-card>
</template>

<script>
export default {
    props: {
        associationsMembership: {
            type: Array,
            required: true,
        },
    },

    data() {
        return {};
    },

    mounted() {},

    methods: {},
};
</script>
