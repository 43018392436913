<template>
    <v-card outlined height="100%" color="primary" class="mt-2">
        <v-card-text>
            <loader
                :isLoading="isLoading"
                :error="error"
                errorMessage="campaigns.showError"
            >
                <v-card-title class="pa-0">
                    {{ $t("campaigns.title") }}
                    <v-spacer></v-spacer>
                    <v-btn color="primary" :to="{ name: 'CreateCampaign' }">
                        {{ $t("button.add") }}
                    </v-btn>
                </v-card-title>
                <v-card-text class="pa-0">
                    <v-data-table
                        :headers="headers"
                        :items="campaigns"
                        @click:row="goToCampaign"
                        :items-per-page="5"
                        :page.sync="page"
                        hide-default-footer
                        @page-count="pageCount = $event"
                        mobile-breakpoint="0"
                    >
                        <template v-slot:[`item.active`]="{ item }">
                            <span v-if="isActive(item)">
                                <v-icon class="ml-1" color="success">
                                    mdi-play-circle-outline
                                </v-icon>
                            </span>
                        </template>
                        <template v-slot:[`item.start_date`]="{ item }">
                            {{ getDate(item.start_date) }}
                        </template>
                        <template v-slot:[`item.end_date`]="{ item }">
                            {{ getDate(item.end_date) }}
                        </template>
                    </v-data-table>
                    <div class="text-center pt-2">
                        <v-pagination
                            v-model="page"
                            :length="pageCount"
                        ></v-pagination>
                    </div>
                </v-card-text>
            </loader>
        </v-card-text>
    </v-card>
</template>

<script>
import moment from "moment";
import Loader from "@/components/utils/Loader";

export default {
    components: {
        Loader,
    },

    data() {
        return {
            campaigns: [],
            associations: [],

            headers: [
                {
                    text: "",
                    value: "active",
                },
                {
                    text: this.$t("campaigns.campaign.year"),
                    sortable: true,
                    value: "year",
                },
                {
                    text: this.$t("campaigns.campaign.startDate"),
                    sortable: true,
                    value: "start_date",
                },
                {
                    text: this.$t("campaigns.campaign.endDate"),
                    sortable: true,
                    value: "end_date",
                },
            ],
            page: 1,
            pageCount: 0,

            isLoadingCampaigns: true,
            isLoadingAssociations: true,
            errorCampaigns: false,
            errorAssociations: false,
        };
    },

    mounted() {
        this.getCampaigns();
        this.getAssociations();
    },

    computed: {
        isLoading() {
            return this.isLoadingCampaigns || this.isLoadingAssociations;
        },
        error() {
            return this.errorCampaigns || this.errorAssociations;
        },
    },

    methods: {
        getCampaigns() {
            this.isLoadingCampaigns = true;
            this.errorCampaigns = false;

            this.$store
                .dispatch("ohme/getCampaigns")
                .then((data) => {
                    this.campaigns = data.data.campaigns;
                    this.isLoadingCampaigns = false;
                })
                .catch(() => {
                    this.isLoadingCampaigns = false;
                    this.errorCampaigns = true;
                });
        },

        getAssociations() {
            this.isLoadingAssociations = true;
            this.errorAssociations = false;

            this.$store
                .dispatch("ohme/getAssociations")
                .then((data) => {
                    this.associations = data.data.associations;
                    this.isLoadingAssociations = false;
                })
                .catch(() => {
                    this.isLoadingAssociations = false;

                    this.errorAssociations = true;
                });
        },

        getDate(date) {
            if(date){
                return moment(date).format("DD/MM/YYYY");
            }
        },

        isActive(campaign) {
            var today = moment().format("YYYY-MM-DD");
            if (campaign.start_date <= today && today <= campaign.end_date) {
                return true;
            } else {
                return false;
            }
        },

        goToCampaign(campaign) {
            this.$router.push({
                name: "Campaign",
                params: {
                    campaignId: campaign.id,
                },
            });
        },
    },
};
</script>
