<template>
    <v-card outlined height="100%" color="primary" class="mt-2">
        <v-card-text>
            <v-card-title class="pa-0">
                {{ $t("federations.index.federations") }}
            </v-card-title>
            <v-card-text class="pa-0">
                <p v-if="federations.length == 0">
                    {{ $t("federations.index.empty") }}
                </p>
                <template v-else>
                    <v-data-table
                        :headers="headers"
                        :items="federations"
                        @click:row="goToFederation"
                        :items-per-page="10"
                        :page.sync="page"
                        hide-default-header
                        hide-default-footer
                        @page-count="pageCount = $event"
                        mobile-breakpoint="0"
                    >
                    </v-data-table>
                    <div class="text-center pt-2">
                        <v-pagination
                            v-model="page"
                            :length="pageCount"
                        ></v-pagination>
                    </div>
                </template>
            </v-card-text>
        </v-card-text>
    </v-card>
</template>

<script>
export default {
    props: {
        federations: {
            type: Array,
            required: true,
        },
        isCFBF: {
            type: Boolean,
            required: true,
        },
    },

    data() {
        return {
            headers: [
                {
                    text: "",
                    value: "name",
                },
            ],
            page: 1,
            pageCount: 0,
        };
    },

    mounted() {},

    methods: {
        goToFederation(federation) {
            this.$router.push({
                name: "Federation",
                params: {
                    federationId: federation.id,
                },
            });
        },
    },
};
</script>
