<template>
    <loader :isLoading="isLoading" :error="error" errorMessage="home.showError">
        <v-row>
            <v-col cols="12" md="6" v-if="isCFBF">
                <campaigns></campaigns>
            </v-col>
            <v-col cols="12" md="6" v-if="isBoard">
                <membership
                    :associationsMembership="associationsMembership"
                ></membership>
            </v-col>
            <v-col cols="12" md="6" v-if="isCFBF || associations.length > 0">
                <associations
                    :associations="associations"
                    :isCFBF="isCFBF"
                ></associations>
            </v-col>
            <v-col cols="12" md="6" v-if="isCFBF || federations.length > 0">
                <federations
                    :federations="federations"
                    :isCFBF="isCFBF"
                ></federations>
            </v-col>
            <v-col
                cols="12"
                md="6"
                v-if="!isCFBF && federationAssociations.length > 0"
            >
                <federation-associations
                    :associations="federationAssociations"
                ></federation-associations>
            </v-col>
            <v-col cols="12" md="6" v-if="isCFBF">
                <users></users>
            </v-col>
        </v-row>

       
    </loader>
</template>

<script>
import Associations from "@/components/associations/Associations";
import FederationAssociations from "@/components/associations/FederationAssociations";
import Campaigns from "@/components/campaigns/Campaigns";
import Federations from "@/components/federations/Federations";
import Membership from "@/components/membership/MembershipCard";
import Loader from "@/components/utils/Loader";
import Users from "@/components/users/Users";

export default {
    components: {
        Associations,
        FederationAssociations,
        Campaigns,
        Federations,
        Membership,
        Loader,
        Users,
    },

    data() {
        return {
            associations: [],
            federations: [],
            federationAssociations: [],
            associationsMembership: [],
            isCFBF: false,
            isBoard: false,

            isLoading: false,
            error: false,
        };
    },

    mounted() {
        this.getHome();
    },

    methods: {
        getHome() {
            this.isLoading = true;
            this.error = false;

            this.$store
                .dispatch("ohme/home")
                .then((data) => {
                    this.associations = data.data.associations;
                    this.federations = data.data.federations;
                    this.federationAssociations =
                        data.data.federationAssociations;
                    this.associationsMembership =
                        data.data.associationsMembership;
                    this.isCFBF = data.data.isCFBF;
                    this.isBoard = data.data.isBoard;
                    this.isLoading = false;
                })
                .catch(() => {
                    this.isLoading = false;
                    this.error = true;
                });
        },

       
    },
};
</script>
